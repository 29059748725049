
const Commnuity=() => {

    return(
        <>
        <div className="Commnuity-div" style={{ boxSizing: "border-box", alignSelf: "center", width: "1280px", margin: "0 auto", backgroundColor: "#1C003B", overflow:"hidden" }}>
             <div className="Commnuity-title" style={{ boxSizing: "border-box", width: "100%", fontSize: "48px", fontWeight: "bold", color: "white", marginTop:"20px" }}>
        <span style={{ margin: "20px" }}>커뮤니케이션</span>
        <hr style={{ margin: "20px", borderColor: "white" }} />
    </div>

    <div style={{ boxSizing:"border-box", display: "flex", flexDirection: "row", justifyContent: "center", width: "100%", borderLeft:"30px"}}>
        <div className="Commnuity-menu" style={{ width: "320px", color: "white" }}>
            <div className="commnuity-menu1" style={{ color: "white", fontSize:"36px", fontWeight:"bold"}}>
                <p>자주 묻는 질문</p>
            </div>
        </div>
        <div style={{ boxSizing:"border-box", width: "900px" , borderLeft: "2px solid white"}}>
        <div className="menu1" style={{ color: "white",margin:"30px" }}>
            <div className="menu1-title" style={{fontSize:"24px", fontWeight:"bold"}}>
                <p>브라우저 캐시 및 쿠키 삭제 방법을 알려주세요.</p>
            </div>
            <div className="menu1-content"style={{whiteSpace: "pre-wrap"}}>
                <span>가캐시 및 쿠키 삭제

                <br/>
<br/>
○ Edge의 경우
<br/>
1. 현재 사용중인 모든 브라우저 창을 종료합니다.
<br/>
2. 빈 페이지의 브라우저를 실행합니다.
<br/>
3. Ctrl + Shift + Del 키를 누릅니다.
<br/>
4. 쿠키 및 기타 사이트 데이터, 캐시된 이미지 또는 파일을 체크한 후, 인터넷 사용 기록 삭제 버튼을 클릭합니다
<br/>

<br/>
○ Chrome의 경우
<br/>
1. 컴퓨터에서 Chrome을 엽니다.
<br/>
2. 오른쪽 상단에서 더보기 더보기 다음 인터넷 사용 기록 삭제를 클릭합니다.
<br/>
3. 지난 1시간 또는 전체 기간과 같이 원하는 기간을 선택합니다.
<br/>
4. 삭제하려는 정보 유형을 선택합니다.
<br/>
5. 인터넷 사용 기록 삭제를 클릭합니다.
<br/>


○ Android
<br/>
 1. Android 기기에서 Chrome앱을 엽니다.
 <br/>
 2. 더보기 다음 인터넷 사용 기록 삭제를 탭합니다.
 <br/>

 <br/>
○ iPhone/iPad
<br/>
 1. iPhone 또는 iPad에서 Chrome 앱을 엽니다.
 <br/>
 2. 더보기 다음 인터넷 사용 기록 삭제를 탭합니다.
 <br/>
 3. 삭제하려는 정보 유형을 선택합니다.
 <br/>
 4. 인터넷 사용 기록 삭제를 탭합니다.
 <br/>
 5. 오른쪽 상단에서 완료를 탭합니다.
 <br/>


새창으로 이용하시고 동일 오류가 발생되는 경우 정부24 헬프데스크 1588-2188로 문의주시기 바랍니다.</span>
            </div>
        </div>



        <div className="menu1" style={{ color: "white",margin:"30px" }}>
            <div className="menu1-title" style={{fontSize:"24px", fontWeight:"bold"}}>
                <p>외부 무료 콘텐츠를 다운로드 한 후 출처 기재시, 어디까지 달아야 할까요?</p>
            </div>
            <div className="menu1-content"style={{whiteSpace: "pre-wrap"}}>
                <span>
                두 번째 줄의 유튜브 링크까지는 필수 !<br/>
                추천인 관련 내용은 필수는 아니다 !<br/>
                무료 음원도 똑같다 !<br/>
                </span>
            </div>
        </div>



        <div className="menu1" style={{ color: "white",margin:"30px" }}>
            <div className="menu1-title" style={{fontSize:"24px", fontWeight:"bold"}}>
                <p>사운드캐스트의 라이선스는 안전한가요? </p>
            </div>
            <div className="menu1-content"style={{whiteSpace: "pre-wrap"}}>
                <span>
                결론부터 말씀드리면, 안전합니다 !<br/>
                유튜브에서는 각각의 저작물마다 컨텐츠 ID(통칭 CID)를 등록하고, 해당 음원이 무단으로 사용되는 경우 그 영상에 저작권과 관련한 클레임을 제기하는 방식으로 저작권을 보호합니다. <br/>
                이와 관련한 자세한 설명 링크입니다.<br/>
                이때 해당 저작권자와 관련하여 협의가 된 영상이라는 것이 상호 확인되면 해당 클레임이 철회되고 정상적으로 조회 수익을 창출할 수 있게 되는데요,<br/>
                사운드캐스트에서는 사운드캐스트에서 제공하는 음원들이 무단으로 이용되는 것을 방지하기 위해 사운드캐스트의 곡을 CID에 등록하고, <br/>
                이와 관련한 클레임이 발생하는 경우 해당 이용자가 사운드캐스트의 멤버십 이용자임을 확인하고 클레임을 해제해 드릴 수 있는 시스템을 구축해 두었습니다.<br/>
                대부분의 저작권 관련 문제들은 10~15분 내외로 자동 해결되나, 경우에 따라 해제에 시간이 걸리거나 정상적으로 해제가 되지 않는 경우가 발생하는데요,<br/>
                이 경우에는 유튜브 자체적으로 제공하는 이의제기 시스템을 이용하면 문제가 해결되고 있습니다.<br/>
                </span>
            </div>
        </div>
        
        </div>
    </div>
    </div>

        
        </>
    );
}
export default Commnuity