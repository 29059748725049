import axios from "axios";
import { getCookie, removeCookie } from "./Cookie";


const CustomAxios = axios.create({

})

CustomAxios.interceptors.request.use(function (request) {
    request.headers.Authorization = "Bearer " + getCookie("accessToken")

    return request;
})

CustomAxios.interceptors.response.use(function (response) {
    return response
},
    function (error) {
        const { response: { status } } = error;

        if (status === 403) {
            removeCookie('accessToken');
            removeCookie('member');
            window.location.href = 'http://soundcast.jumpingcrab.com/'//로그인페이지 경로 작성
        }
    }
)

export default CustomAxios;