import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Pagination from "./Pagination";
import { initSong, Song } from "../type/SongType";
import { setPlaySong } from "../features/songSlice";
import { useNavigate } from "react-router-dom";

import ModifyMusic from "../pages/ModifyMusic";

const MyPageSong = ({
  activeSongNo,
  setActiveSongNo,
  song,
  deleteList,
  setDeleteList,
}: {
  activeSongNo: number | null;
  setActiveSongNo: (no: number) => void;
  song: { list: Song[]; currentSong: Song };
  deleteList: number[];
  setDeleteList: (noList: number[]) => void;
}) => {
  const dispatch = useDispatch();
  const navi = useNavigate();

  //모디파이
  const [showModifySong, setShowModifySong] = useState(false);
  const [modifySong, setModifySong] = useState<Song>(initSong);
  const modifySongHandler = (e: MouseEvent, song: Song) => {
    e.stopPropagation();
    setShowModifySong(true);
    setModifySong(song);
  };
  const modifySongCloseHandler = () => {
    setShowModifySong(false);
  };

  useEffect(() => {
    if (activeSongNo !== null) {
      dispatch(setPlaySong(activeSongNo));
    }
  }, [activeSongNo, dispatch, song.list]);

  const itemsPerPage = 20;

  const [currentPage, setCurrentPage] = useState<number>(1);
  const totalPages: number = Math.ceil(song.list.length / itemsPerPage);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = song.list.slice(indexOfFirstItem, indexOfLastItem);

  // const [deleteList, setDeleteList] = useState([0]);

  const onCheckBoxChange = (
    e: ChangeEvent<HTMLInputElement>,
    songNo: number
  ) => {
    if (e.target.checked) {
      setDeleteList([...deleteList, songNo]);
    } else {
      setDeleteList(deleteList.filter((no) => no !== songNo));
    }
  };

  const onClickSongCard = (songNo: number) => {
    dispatch(setPlaySong(songNo));
    navi("/song/detail/" + songNo);
  };

  //수정 (검색함수 props에 추가, 이미지 경로 지정, 아티스트명 클릭시 아티스트 페이지로 이동, 장르, 분위기 data-songno 수정 클릭 이벤트 부여)

  const serverImagePath =
    "https://soundcast-api.jumpingcrab.com/soundcast/resource/";

  return (
    <div
      className="mysong"
      style={{
        minWidth: "1300px",
        minHeight: "60vh",
        border: "1px solid lightgrey",
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      {currentItems.map((Song, index) => (
        <div
          key={index}
          style={{
            margin: "20px 2.39%",
            width: "194px",
            height: "220px",
            display: "flex",
            flexDirection: "column",
            cursor: "pointer",
          }}
          onClick={() => onClickSongCard(Song.songNo)}
        >
          <div
            className="hoverImage"
            style={{
              width: "100%",
              height: "154px",
              boxSizing: "border-box",
              flexGrow: "1",
              position: "relative",
            }}
          >
            <img
              className="modifyImage"
              src={serverImagePath + "public/member/modifyInfo.png"}
              style={{
                position: "absolute",
                width: "20px",
                height: "20px",
                top: "10px",
                left: "10px",
                borderRadius: "5px",
                backgroundColor: "white",
              }}
              onClick={(e) => modifySongHandler(e, Song)}
              alt="Modify"
            />
            <img
              src={
                Song.songImage.songImageNo !== 0
                  ? serverImagePath +
                    Song.songImage.songImagePathName +
                    Song.songImage.songImageName
                  : serverImagePath + "images/song/song-image.png"
              }
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderTopLeftRadius: "7px",
                borderTopRightRadius: "7px",
              }}
              alt="SongImg"
            />
            <input
              type="checkbox"
              style={{
                position: "absolute",
                width: "20px",
                height: "20px",
                top: "10px",
                right: "10px",
              }}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => {
                onCheckBoxChange(e, Song.songNo);
              }}
              checked={deleteList.includes(Song.songNo)}
            />
          </div>
          {/* {showModifySong ? <ModifyMusic show={showModifySong} handleClose={modifySongCloseHandler} selectSong={Song} /> : null} */}
          <div
            style={{
              border: "1px solid black",
              width: "100%",
              height: "46px",
              boxSizing: "border-box",
              fontWeight: "bolder",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderBottomLeftRadius: "7px",
              borderBottomRightRadius: "7px",
            }}
          >
            <p
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                maxWidth: "60%",
              }}
            >
              {Song.songTitle}
            </p>
          </div>
        </div>
      ))}

      {
        <ModifyMusic
          show={showModifySong}
          handleClose={modifySongCloseHandler}
          selectSong={modifySong}
        />
      }
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          flexShrink: "0",
        }}
      >
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default MyPageSong;
