import MainBanner from "./MainBanner";
import GenreSearch from "./GenreSearch";
import SearchBar from "./SearchBar";
import SearchList from "./SearchList";
import { CSSProperties, useEffect, useState } from "react";
import { getCookie, setCookie } from "../utils/Cookie";

function MainPage() {
  const modelessCss: CSSProperties = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "100",
  };
  const dialogCss: CSSProperties = {
    backgroundColor: "#1c003b",
    borderRadius: "8px",
    width: "550px",
    height: "320px",
    color: "white",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    border: "3px solid white",
  };

  const [modelessClose, setModelessClose] = useState(false);

  const closeChecked = () => {
    setModelessClose(!modelessClose);
  };

  const closeDayChecked = () => {
    const dateString = todayStr();

    closeChecked();
    setCookie("soundcast_modalessFlag", dateString);
  };

  useEffect(() => {
    const modelessFlag = getCookie("soundcast_modalessFlag");
    if (modelessFlag) {
      const dateString = todayStr();
      dateString === modelessFlag && setModelessClose(true);
    }
  }, []);

  function todayStr() {
    const today = new Date();

    const year = today.getFullYear();
    const month = ("0" + (today.getMonth() + 1)).slice(-2);
    const day = ("0" + today.getDate()).slice(-2);

    const dateString = year + "-" + month + "-" + day;

    return dateString;
  }

  return (
    <>
      <div
        style={
          modelessClose ? { display: "none" } : { ...dialogCss, ...modelessCss }
        }
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "50px",
            lineHeight: "15px",
          }}
        >
          <h2>알려드립니다</h2>
        </div>
        <div
          style={{
            padding: "0 20px",
            height: "100%",
            borderTop: "2px dashed white",
          }}
        >
          <p>본 사이트는 포트폴리오 용으로 만들어졌습니다. </p>
          <p>
            사이트 내의 모든 컨텐츠들은 그 어떤 상업적 용도로도 쓰이지 않으며
          </p>
          <p>이 사이트의 Official 영역에 존재하는 음원들은</p>
          <p>모두 저작권 표기를 준수합니다.</p>
          <p>
            Unofficial 영역은 사용자가 자유롭게 음원을 올릴 수 있는 공간이며
          </p>
          <p>저작권 문제 발생 시 경고 없이 음원이 삭제될 수 있습니다.</p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "0 5px",
          }}
        >
          <div>
            <input
              type="checkbox"
              name="modelessClose"
              id="modelessClose"
              onChange={closeChecked}
            />
            <label style={{ font: "15px bold white" }} htmlFor="modelessClose">
              닫기
            </label>
          </div>
          <div style={{ marginLeft: "10px" }}>
            <input
              type="checkbox"
              name="modelessDayClose"
              id="modelessDayClose"
              onChange={closeDayChecked}
            />
            <label
              style={{ font: "15px bold white" }}
              htmlFor="modelessDayClose"
            >
              오늘 하루 그만보기
            </label>
          </div>
        </div>
      </div>
      <MainBanner />
      <GenreSearch searchGenre={-1} searchMood={-1} />
      <SearchBar searchKeyword="" />
      <SearchList />
    </>
  );
}

export default MainPage;
