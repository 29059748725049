import { ChangeEvent, CSSProperties, MouseEvent, useRef, useState } from 'react';
import '../index.css'
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import axios from '../utils/CustomAxios';

const MusicReportModal = ({setShowReportModal}:{setShowReportModal:(toggle:boolean) => void}) => {
    const commonFlexStyle: CSSProperties = {
        display: "flex",
    };

    const commonColumnFlexStyle: CSSProperties = {
        ...commonFlexStyle,
        flexDirection: "column",
    };

    const commonTextStyle: CSSProperties = {
        textAlign: "start",
        font: "bolder 36px Inter",
    };

    const commonRadioInputStyle: CSSProperties = {
        margin: "0 10px",
    };

    const commonLabelStyle: CSSProperties = {
        font: "bold 16px Inter",
    };

    const containerStyle: CSSProperties = {
        position: "fixed",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#1C003B",
        borderRadius: "10px",
        minWidth: "600px",
        alignSelf: "center",
        minHeight: "450px",
        padding: "10px",
        ...commonColumnFlexStyle,
    };

    const headerStyle: CSSProperties = {
        display: "flex",
        justifyContent: "space-between",
        color: "white",
        font: "bolder 34px Inter",
        padding: "0 5px",
        marginBottom: "10px"
    };

    const contentContainerStyle: CSSProperties = {
        flexGrow: "1",
        backgroundColor: "#F0ECFD",
        borderRadius: "10px",
        padding: "15px 20px",
        ...commonColumnFlexStyle,
    };

    const footerStyle: CSSProperties = {
        ...commonFlexStyle,
        justifyContent: "space-between",
        alignItems: "center",
    };

    const sendButtonStyle: CSSProperties = {
        backgroundColor: "#1C003B",
        border: "2px solid #770ABF",
        color: "white",
        font: "18px Inter",
        padding: "5px 24px",
        borderRadius: "10px",
    };

    const member = useSelector((state:RootState) => state.member);
    const song = useSelector((state:RootState) => state.song);

    const [reportContent, setReportContent] = useState('음원이 아닌 파일 업로드');

    const [reportInput, setReportInput] = useState('');

    const textareaRef = useRef(null);

    const onChangeInput = (e:ChangeEvent<HTMLTextAreaElement>) => {
        setReportInput(e.target.value);
    }

    const radioCheckHandler = (e:ChangeEvent<HTMLInputElement>) => {
        if(e.target.checked) {
            setReportContent(e.target.value);
        }
    }

    const onSendReport = (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if(!member.memberNo && !song.currentSong.songNo) {
            return;
        }

        if(reportContent.includes("[기타]")) {
            setReportContent(reportContent + " " + reportInput);
        }

        axios.post("https://soundcast-api.jumpingcrab.com/soundcast/song/report", {
            reportSongNo: song.currentSong.songNo,
            reportMemberNo: member.memberNo,
            reportText: reportContent
        })
            .then(res => {
                if(res.data) {
                    alert("신고되었습니다.")
                    setShowReportModal(false);
                }
            })
    }

    return (
        <div style={containerStyle}>
            <header style={headerStyle}>
                <span>신고</span><span style={{cursor:"pointer"}} onClick={() => setShowReportModal(false)}>&times;</span>
            </header>
            <section style={contentContainerStyle}>
                <div style={commonTextStyle}>신고사유</div>
                <div style={{ ...commonColumnFlexStyle, flexGrow: "1", paddingLeft: "30px", alignItems: "flex-start" }}>
                    <div style={{...commonFlexStyle, alignItems: "center" , marginTop: "20px" }}>
                        <input 
                            style={commonRadioInputStyle} 
                            type="radio" 
                            name="reportReason" 
                            id="report_reason1" 
                            value={"음원이 아닌 파일 업로드"}
                            onChange={radioCheckHandler}
                            defaultChecked />
                        <label style={commonLabelStyle} htmlFor="report_reason1">음원이 아닌 파일 업로드</label>
                    </div>
                    <div style={{...commonFlexStyle, alignItems: "center" , marginTop: "20px" }}>
                        <input 
                            style={commonRadioInputStyle} 
                            type="radio" 
                            name="reportReason" 
                            id="report_reason2"
                            value={"타인의 저작물 도용"}
                            onChange={radioCheckHandler}
                            />
                        <label style={commonLabelStyle} htmlFor="report_reason2">타인의 저작물 도용</label>
                    </div>
                    <div style={{...commonFlexStyle, alignItems: "center" , marginTop: "20px" }}>
                        <input 
                            style={commonRadioInputStyle} 
                            type="radio" 
                            name="reportReason" 
                            id="report_reason3" 
                            value={"폭력적 혹은 혐오스러운 컨텐츠"}
                            onChange={radioCheckHandler}
                            />
                        <label style={commonLabelStyle} htmlFor="report_reason3">폭력적 혹은 혐오스러운 컨텐츠</label>
                    </div>
                    <div style={{ marginTop: "20px", ...commonColumnFlexStyle, width: "100%", flexGrow: "1", paddingBottom: "5px" }}>
                        <div style={commonFlexStyle}>
                            <input 
                                style={commonRadioInputStyle} 
                                type="radio" 
                                name="reportReason" 
                                id="report_reason4" 
                                value={"[기타]"}
                                onChange={radioCheckHandler}
                                />
                            <label style={commonLabelStyle} htmlFor="report_reason4">기타</label>
                        </div>
                        <div style={{ paddingLeft: "30px", flexGrow: "1", ...commonColumnFlexStyle }}>
                            <textarea 
                                name="etcReason"
                                style={{ boxSizing: "border-box", width: "100%", resize: "none", flexGrow: "1", outline: "none"}}
                                onChange={onChangeInput}
                                ref={textareaRef}>
                            </textarea>
                        </div>
                    </div>
                </div>
                <div style={footerStyle}>
                    <span style={{ font: "12px Inter", color: "gray" }}>※ 반복된 허위 신고 시 제제를 받을 수 있습니다</span>
                    <button style={sendButtonStyle} onClick={onSendReport}>보내기</button>
                </div>
            </section>
        </div>

    );
}

export default MusicReportModal;